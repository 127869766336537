.btn {
  border-radius: 50px;
  padding: .375rem .75rem !important; }
  .btn-outline-secondary {
    border-color: #6C757D; }
    .btn-outline-secondary:hover, .btn-outline-secondary:focus {
      background: #5a6268;
      border-color: #5a6268;
      text-decoration: none; }
  .btn-secondary {
    font-weight: 700;
    font-size: 13px; }
    .btn-secondary:hover, .btn-secondary:focus {
      background: #5a6268;
      border-color: #5a6268;
      text-decoration: none; }

footer {
  background: #1A1A1A;
  color: white;
  font-size: 12px; }
  @media screen and (max-width: 991px) {
    footer {
      text-align: center; } }
  footer .logo {
    height: 20px; }
  footer ul {
    display: flex; }
    footer ul li {
      padding: 5px; }
      footer ul li a {
        color: white; }
        footer ul li a:hover, footer ul li a:focus {
          color: #FEFEFE; }
